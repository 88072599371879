// Sass mixins for *equal* columns grid container
// example : .grid-perso { @include grid(12); }
@mixin grid($number:2, $gutter:$marge-horizontal) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -$gutter;

    /* inline-block fallback for IE9 generation */
    letter-spacing: -0.31em;

    & > * {
        flex: 0 1 auto;
        @include grid-item($number:$number, $gutter:$gutter);
        min-width: 0;
        margin-left: $gutter;

        /* inline-block fallback for IE9 generation */
        display: inline-block;
        vertical-align: top;
        letter-spacing: normal;
    }
}

@mixin grid-item($number:2, $gutter:$marge-horizontal) {
    width: calc(100% * 1 / #{$number} - #{$gutter} - .01px);
}

@mixin spritesdd($spritesheet, $prefixe:false) {
    .icon-img {
        background-image: url(map-get($spritesheet, 'image'));
    }
    .icon-before {
        content: '';
        display: block;
    }
    @each $sprite in map-get($spritesheet, 'sprites') {
        $sprite-name: map-get($sprite, 'name');
        @debug if($prefixe, '', 'icon');
        @if str-slice($sprite-name, 0, 5) == $prefixe or $prefixe == false {
            .#{$sprite-name} {
                @extend .icon-img;
                @include sprite-position($sprite);
                @include sprite-width($sprite);
                @include sprite-height($sprite);
            }
            .#{$sprite-name}-bf:before {
                @extend .icon-before;
                @extend .#{$sprite-name};
            }
        }
    }
}
