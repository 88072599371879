@charset "UTF-8";


.breadcrumb {
    &, ol {
        @extend .ul-unstyled;
    }
    li {
        display: inline-block;
    }

    a {
        display: inline-block;

        &:before {
            content: '»';
            display: inline-block;
            margin-right: .5em;
            text-decoration: none !important;
        }
    }
}
