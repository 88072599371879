.msg {
    position: relative;
    @extend .box1;

    &.is-reponse {
        background: $vert-;
    }

    &-avatar {
        display: table-cell;
        vertical-align: top;
        padding-right: 1rem;
        padding-top: .2em;
        width: calc(60px + 1rem);
    }
    &-body {
        display: table-cell;
        vertical-align: top;
    }
    &-titre {
        @extend .titre;
        @extend .titre-2;
        margin: 0 0;

        .msg-large & {
            margin-right: 40px;
        }
    }
    &-titre-plus {
        font-size: .7em;
        font-family: $font-base;
    }
    &-stitre {
        margin: 0 0;
        font-size: .9em;
    }
    &-texte {
        margin: .7em 0;
        @extend .contenu;

        blockquote {
            margin: 1.6rem 2rem;
            border: 1px solid $bleu---;
            padding: 1rem;
            background-color: $bleu-----;

            cite {
                font-weight: bold;
            }
        }
    }
    &-nav {
        font-size: .9em;
        text-align: right;
        color: $gris--;

        .msg-large & {
            position: absolute;
            right: 0;
            top: 1.5em;
        }
    }
    &-count {
        position: relative;
        display: inline-block;
        padding-left: map-get($icon-commentaires, 'width') + 5px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: .1em;
            left: 0;
            @extend .icon-commentaires;
        }
    }
    &-link {
        padding-left: 1.5em;
    }


    &-sm {
        overflow: hidden; /* fix url trop longue qui dépasse sur accueil */
        font-size: 1.4rem;

        & .msg-titre {
            @extend .titre-3;
        }
    }

    &-new {
        background-color: $gris-----;
    }

    &-epingle {
        background-color: $orange---;
    }

    @media (max-width: 600px) {
        &-avatar {
            width: calc(30px + 1rem);
        }

        &-texte {
            blockquote {
                margin: 0;
            }
        }
    }
}