$accordion-texte_height: 10em;

.accordion-item {
    position: relative;
    & > :last-child {
        overflow: hidden;
    }
    &.isClose {
        & > :last-child {
            overflow: hidden;
            max-height: $accordion-texte_height;
        }
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
            width: 100%;
            min-height: $accordion-texte_height;
            background-image: linear-gradient(to bottom, rgba(white,0.2), white);
            cursor: pointer;
        }
    }
}