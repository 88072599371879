.titre {
    display: block;
    font-family: $font-titre;
    font-weight: normal;
    margin: 1em 0;

    a:hover, &a:hover {
        color: $bleuf;
    }

    &-1 {
        padding-bottom: .6em;
        border-bottom: 1px solid $gris----;
        font-size: 2.2rem;
    }
    &-2 {
        font-size: 2rem;
    }
    &-3 {
        font-size: 1.6rem;
    }

    &-small {
        font-size: 1.6rem;
    }
}