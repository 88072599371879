.pub {
    margin-top: $marge-vertical * 2;
    border-top: 1px solid $gris---;

    &-titre {
        @extend .titre, .titre-3;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$marge-horizontal;
        @extend .ul-unstyled;

        > * {
            flex: 0 0 auto;
            width: 100px;
            min-width: 0;
            margin-left: $marge-horizontal;
            margin-bottom: $marge-vertical;
        }
    }

    &-item {
        display: block;
        height: 100%;
        border: 1px solid transparent;

        &-titre {
            font-size: 1.1rem;
            text-align: center;
        }

        &:hover {
            border-color: $gris--;
            text-decoration: none;
            background: $gris----;
        }
    }
}