/* ---------------------------------- */
/* ==state helpers                    */
/* ---------------------------------- */

/* invisible for all */
.is-hidden,
[hidden] {
    display: none;
}

/* hidden but not for an assistive technology like a screen reader, Yahoo! method */
.visually-hidden {
    position: absolute !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
}

.is-disabled,
[disabled] {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
    filter: grayscale(1);
}


/* spacing helpers
p,m = padding,margin
a,t,r,b,l = all,top,right,bottom,left
s,m,l,n = small, medium, large, none
*/
.man,
.ma0 {
    margin: 0;
}

.pan,
.pa0 {
    padding: 0;
}
/*
.mas {
    margin: ;
}

.mam {
    margin: $medium-value;
}

.mal {
    margin: $large-value;
}

.pas {
    padding: $small-value;
}

.pam {
    padding: $medium-value;
}

.pal {
    padding: $large-value;
}
*/
.mtn,
.mt0 {
    margin-top: 0;
}

.mts {
    margin-top: $marge-vertical / 2;
}

.mtm {
    margin-top: $marge-vertical;
}

.mtl {
    margin-top: $marge-vertical * 2;
}

.mrn,
.mr0 {
    margin-right: 0;
}

.mrs {
    margin-right: $marge-horizontal / 2;
}

.mrm {
    margin-right: $marge-horizontal;
}

.mrl {
    margin-right: $marge-horizontal * 2;
}

.mbn,
.mb0 {
    margin-bottom: 0;
}

.mbs {
    margin-bottom: $marge-vertical / 2;
}

.mbm {
    margin-bottom: $marge-vertical;
}

.mbl {
    margin-bottom: $marge-vertical * 2;
}

.mln,
.ml0 {
    margin-left: 0;
}

.mls {
    margin-left: $marge-horizontal / 2;
}

.mlm {
    margin-left: $marge-horizontal;
}

.mll {
    margin-left: $marge-horizontal * 2;
}

.mauto {
    margin: auto;
}

.mtauto {
    margin-top: auto;
}

.mrauto {
    margin-right: auto;
}

.mbauto {
    margin-bottom: auto;
}

.mlauto {
    margin-left: auto;
}

.ptn,
.pt0 {
    padding-top: 0;
}

.pts {
    padding-top: $marge-vertical /2 ;
}

.ptm {
    padding-top: $marge-vertical;
}

.ptl {
    padding-top: $marge-vertical * 2;
}

.prn,
.pr0 {
    padding-right: 0;
}

.prs {
    padding-right: $marge-horizontal / 2;
}

.prm {
    padding-right: $marge-horizontal;
}

.prl {
    padding-right: $marge-horizontal * 2;
}

.pbn,
.pb0 {
    padding-bottom: 0;
}

.pbs {
    padding-bottom: $marge-vertical / 2;
}

.pbm {
    padding-bottom: $marge-vertical;
}

.pbl {
    padding-bottom: $marge-vertical * 2;
}

.pln,
.pl0 {
    padding-left: 0;
}

.pls {
    padding-left: $marge-horizontal / 2;
}

.plm {
    padding-left: $marge-horizontal;
}

.pll {
    padding-left: $marge-horizontal * 2;
}