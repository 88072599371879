@charset "UTF-8";

.ul {

    &-unstyled {
        list-style: none;
        padding-left: 0;
    }

    &-qcmex {
        @extend .ul-unstyled;

        li {
            position: relative;
            margin: .5em 0;
            padding-left: 1.5em;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: .25em;
                left: 0;
                @extend .icon-qcm;
            }
        }

        s {
            text-decoration: none;
        }

        .isChecked {
            &:before {
                @extend .icon-qcm-checked;
            }
        }
    }

    &-fleche {
        @extend .ul-unstyled;

        li {
            position: relative;
            margin: .5em 0;
            padding-left: 1.5em;

            &:before {
                content: '→';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &-nav {
        @extend .ul-unstyled;

        > li {
            margin: 2em 0;
            font-size: 1.4rem !important;

            > ul > li {
                margin: 1em 0;
            }
        }
    }

    &-sm {
        font-size: $font-small-size;
    }
}