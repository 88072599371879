.table {
    width: 100%;
    margin: 3.2rem 0;
    overflow: scroll;
    border: 1px solid white;

    & caption {
        margin: 1rem 0;
        color:#369;
        background-color:#a1c2e1;
    }
    & thead {
        background-color: #626262;
        color: white;
        font-weight: bold;
        text-align: center;
    }
    & td,
    & th {
        border-collapse: collapse;
        border-bottom: 1px solid #484848;
        padding: 0.5rem;
    }
    & td + td,
    & th + th {
        border-left: 1px solid white;
    }
    & tbody tr:nth-child(even) {
        background-color: #F7F7F7;
    }
}