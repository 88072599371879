/* ==|====================
Modules/Sprites
======================= */
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
    width: map-get($icon-home, 'width');
}

At the bottom of this section, we provide information about the spritesheet itself
*/
$icon-commentaires: (
    name: 'commentaires',
    x: 256px,
    y: 115px,
    offset-x: -256px,
    offset-y: -115px,
    width: 18px,
    height: 18px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-exclamation: (
    name: 'exclamation',
    x: 256px,
    y: 95px,
    offset-x: -256px,
    offset-y: -95px,
    width: 20px,
    height: 20px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-external-reference: (
    name: 'external-reference',
    x: 267px,
    y: 49px,
    offset-x: -267px,
    offset-y: -49px,
    width: 7px,
    height: 7px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-external: (
    name: 'external',
    x: 146px,
    y: 85px,
    offset-x: -146px,
    offset-y: -85px,
    width: 10px,
    height: 10px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-facebook: (
    name: 'facebook',
    x: 90px,
    y: 85px,
    offset-x: -90px,
    offset-y: -85px,
    width: 30px,
    height: 30px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-fleche-left: (
    name: 'fleche-left',
    x: 133px,
    y: 85px,
    offset-x: -133px,
    offset-y: -85px,
    width: 13px,
    height: 17px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-fleche-right: (
    name: 'fleche-right',
    x: 120px,
    y: 85px,
    offset-x: -120px,
    offset-y: -85px,
    width: 13px,
    height: 17px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-hamburger: (
    name: 'hamburger',
    x: 176px,
    y: 145px,
    offset-x: -176px,
    offset-y: -145px,
    width: 29px,
    height: 26px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-partage: (
    name: 'partage',
    x: 248px,
    y: 145px,
    offset-x: -248px,
    offset-y: -145px,
    width: 15px,
    height: 14px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-picto-b-n-s-s-a: (
    name: 'picto-BNSSA',
    x: 0px,
    y: 86px,
    offset-x: 0px,
    offset-y: -86px,
    width: 85px,
    height: 88px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-picto-accident-plage: (
    name: 'picto-accident-plage',
    x: 176px,
    y: 0px,
    offset-x: -176px,
    offset-y: 0px,
    width: 100px,
    height: 49px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-picto-activites-nautique: (
    name: 'picto-activites-nautique',
    x: 0px,
    y: 174px,
    offset-x: 0px,
    offset-y: -174px,
    width: 91px,
    height: 36px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-picto-droits-des-baignades: (
    name: 'picto-droits-des-baignades',
    x: 176px,
    y: 95px,
    offset-x: -176px,
    offset-y: -95px,
    width: 80px,
    height: 50px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-picto-milieu-marin: (
    name: 'picto-milieu-marin',
    x: 91px,
    y: 174px,
    offset-x: -91px,
    offset-y: -174px,
    width: 109px,
    height: 28px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-picto-organisation-surveillance: (
    name: 'picto-organisation-surveillance',
    x: 176px,
    y: 49px,
    offset-x: -176px,
    offset-y: -49px,
    width: 91px,
    height: 46px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-picto-techniques-sauvetage: (
    name: 'picto-techniques-sauvetage',
    x: 90px,
    y: 0px,
    offset-x: -90px,
    offset-y: 0px,
    width: 86px,
    height: 85px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-picto-textes-loi: (
    name: 'picto-textes-loi',
    x: 0px,
    y: 0px,
    offset-x: 0px,
    offset-y: 0px,
    width: 90px,
    height: 86px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-qcm-checked: (
    name: 'qcm-checked',
    x: 228px,
    y: 145px,
    offset-x: -228px,
    offset-y: -145px,
    width: 20px,
    height: 16px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-qcm: (
    name: 'qcm',
    x: 263px,
    y: 145px,
    offset-x: -263px,
    offset-y: -145px,
    width: 13px,
    height: 13px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-recherche: (
    name: 'recherche',
    x: 205px,
    y: 145px,
    offset-x: -205px,
    offset-y: -145px,
    width: 23px,
    height: 24px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$icon-top: (
    name: 'top',
    x: 256px,
    y: 133px,
    offset-x: -256px,
    offset-y: -133px,
    width: 19px,
    height: 12px,
    total-width: 276px,
    total-height: 210px,
    image: '../img/sprite.png'
);
$spritesheet: (
    width: 276px,
    height: 210px,
    image: '../img/sprite.png',
    sprites: ($icon-commentaires, $icon-exclamation, $icon-external-reference, $icon-external, $icon-facebook, $icon-fleche-left, $icon-fleche-right, $icon-hamburger, $icon-partage, $icon-picto-b-n-s-s-a, $icon-picto-accident-plage, $icon-picto-activites-nautique, $icon-picto-droits-des-baignades, $icon-picto-milieu-marin, $icon-picto-organisation-surveillance, $icon-picto-techniques-sauvetage, $icon-picto-textes-loi, $icon-qcm-checked, $icon-qcm, $icon-recherche, $icon-top, )
);

/*
The provided mixins are intended to be used with variables directly

.icon-home {
    @include sprite-width($icon-home);
}

.icon-email {
    @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
    width: map-get($sprite, 'width');
}

@mixin sprite-height($sprite) {
    height: map-get($sprite, 'height');
}

@mixin sprite-position($sprite) {
    background-position: map-get($sprite, 'offset-x') map-get($sprite, 'offset-y');
}

@mixin sprite-image($sprite) {
    background-image: url(map-get($sprite, 'image'));
}

@mixin sprite($sprite) {
    @include sprite-image($sprite);
    @include sprite-position($sprite);
    @include sprite-width($sprite);
    @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites(map-get($spritesheet, 'sprites'));
*/

@mixin sprites($sprites) {
    @each $sprite in $sprites {
        $sprite-name: map-get($sprite, 'name');
        .icon-#{$sprite-name} {
            @include sprite($sprite);
        }
    }
}

@function getSpritesByPrefixe($spritesheet, $prefixes) {
    $sprites2: map-get($spritesheet, 'sprites');
    $i: 0;
    $sprites3: ();
    $sprites4: ();
    @each $sprite in $sprites2 {
        $sprites3: map-merge($sprites3, ($i: $sprite));
        $i: $i + 1;
    }

    @each $prefixe in $prefixes {
        $sprites: ();
        $i: 0;
        @each $key, $sprite in $sprites3 {
            $sprite-name: map-get($sprite, 'name');
            @if str-slice($sprite-name, 0, str_length($prefixe)) == $prefixe {
                $sprites3: map-remove($sprites3, $key);
                $sprites: map-merge($sprites, ($i: $sprite));
                $i: $i + 1;
            }
        }
        $sprites4: map-merge($sprites4, ($prefixe: ($sprites)));
    }
    $sprites4: map-merge($sprites4, (0: $sprites3));
    $sprites4: map-get($sprites4, $prefixes);
    @return $sprites4;
}

