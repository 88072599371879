@mixin tagcloud($index) {
    &-item-#{$index} {
        font-size: 50% + ($index * 5);
        @if $index >= 8 {
            font-weight: bold;
        }
    }
}



.tag {
    padding-left: 0;

    li {
        display: inline;
    }

    @for $i from 1 through 10 {
        @include tagcloud($i);
    }

    &-sm {
        font-size: 1.2rem;
    }
}
