.box1 {
    padding: 1em 0;
    border-bottom: 1px dashed $gris---;

    & > a:hover {
        text-decoration: none;
        color: $bleuf;
    }
}

.box2 {
    margin-top: $marge-vertical;
    margin-bottom: $marge-vertical;
    padding-bottom: 1rem;
    border: 1px solid $bleu----;
    background-color: $bleu-----;

    &:first-child {
        margin-top: 0;
    }

    > *:not(img) {
        padding: 0 1rem;
    }
}

.box3 {
    @extend .box1;
    font-size: 1.4rem;

    &-titre {
        @extend .titre;
        @extend .titre-3;
    }
}
