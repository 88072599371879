/* ==|====================
   Layout/Header
   ======================= */

.l-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 0 10px;
    height: $header-height;
    background: rgba($bleu, .95);
    font-family: $font-titre;
    font-size: 1.6rem;
    color: white;
}
    .l-header > * {
        flex: 0 1 auto;
        align-self: center;
        display: block;
    }
    .l-header > * + * {
        padding-left: 10px;
    }
    .header-logo {
        padding: 0 20px 0 0;
    }
        .header-logo img,
        .header-sigle img{
            vertical-align: top;
        }
    .header-menu {
    }
        .header-menu ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                position: relative;
            }
        }
            .header-menu-rub {
                display: block;
                text-align: center;
                color: white;
            }
            .header-menu-rub:hover {
                background-color: $bleu---;
                text-decoration: none;
            }
            .header-menu-notification {
                position: absolute;
                top: 1.3em;
                left: 0em;
                padding: .05em .4em;
                font-size: $font-small-size;
                font-weight: bold;
                background-color: rgba($warning, .9);

                &:hover {
                    background-color: $warning-;
                    text-decoration: none;
                }
            }
    .header-recherche {
        flex: 1;
        text-align: right;
        font-size: 1.3rem;
        font-family: $font-base;
        color: $bleu--;

        &-content {
            display: inline-block;
            position: relative;
            margin-left: auto;
        }
            &-input {
                position: relative;
                z-index: 1;
                border: 2px solid transparent;
                border-radius: 4px;
                width: 0;
                transition: width .4s;
                padding: .5em .6em .5em 1em;
                background-color: transparent;

                &:hover,
                &:focus {
                    width: 140px;
                    padding-left: map-get($icon-recherche, 'width') + 5px;
                    border-color: $bleu-;
                }
            }
            &-button {
                position: absolute;
                left: 4px;
                top: 6px;
                @include sprite($icon-recherche);
                border: 0 none;
                background-color: transparent;

                span {
                    @extend .visually-hidden
                }
            }
    }
    .header-compte {

    }
    .header-baseline {
        font-size: 1.4rem;
    }


@media (max-width: 800px) {
    .l-header {
        min-height: $header-height;
    }
        .header-logo {
            display: none;
        }
        .header-sigle {
            flex: 0 0 auto;
            position: relative;
        }
        .header-sigle:before {
            content: '';
            position: absolute;
            left: - map-get($icon-hamburger, 'width');
            @include sprite($icon-hamburger);
            cursor: pointer;
        }
        .header-menu {
            position: fixed;
            z-index: 100;
            top: $header-height;
            bottom: 0;
            left: 0;
            width: 220px;
            padding: 0;
            transform: translate3d(-220px, 0px, 0px);
            transition: transform 0.3s ease 0s;
            background-color: $gris;
        }
        .header-menu.is-active {
            transform: translate3d(0px, 0px, 0px);
        }
            .header-menu-rub {
                display: block;
                padding: 2rem .5rem;
                border-bottom: 2px solid $bleu-x;
            }
        .header-compte {
            font-size: 1.2rem;
        }
        .header-baseline {
            font-size: 1rem;
        }
}

@media (min-width: 801px) {
    .header-sigle {
        display: none;
    }
    .header-menu {
        align-self: stretch;
        height: 100%;
        flex: 2;
        margin: 0;
    }
        .header-menu ul {
            display: flex;
            height: 100%;
        }
            .header-menu li {
                flex: 1;
                display: block;
                height: 100%;
                transition: flex .4s;
            }
            .header-menu li:first-child {
                border-left: 2px solid $bleu-x;
            }
            .header-menu li:hover,
            .header-menu li:focus {
                flex: 2;
            }
                .header-menu-rub {
                    display: flex;
                    height: 100%;
                    border-right: 2px solid $bleu-x;
                }
                    .header-menu-rub span {
                        display: block;
                        margin: auto;
                        padding: 0 1rem;
                    }
    }