.alert {
    margin: 3.2rem 0;
    padding: 2rem 2rem;
    border-top: 1px solid;
    border-bottom: 1px solid;

    &-success {
        border-color: $success;
        background-color: $success-;
    }
    &-info {
        border-color: $info;
        background-color: $info-;
    }
    &-warning {
        border-color: $warning;
        background-color: $warning-;
    }
    &-danger {
        border-color: $danger;
        background-color: $danger-;
    }
}