.partage {

    ul {
        @extend .ul-unstyled;

        li {
            display: inline;
            padding-left: 1em;
        }
    }

    &-icon {
        display: inline-block;
        vertical-align: middle;
    }

    &-hidden {
        @extend .visually-hidden;
    }
}