.annonce {
    @extend .box1;

    a {
        @extend .grid-3;

        > *:first-child {
            flex: 1;
        }
        > *:first-child {
            flex: 1;
        }
        > *:last-child {
            flex: 0 1 100px;
        }
    }

    &:nth-child(even) {
        background-color: $bleu----;
    }
    &:hover {
        background-color: $gris------;
    }

    &-titre {
        @extend .titre-3;
        margin: 0;
    }
    &-employeur {
        color: $bleu-x;
    }
    &-adresse {
        padding-top: 1em;
    }
    &-diplome {
        @extend .ul-unstyled;
        margin: 0;

        li {
            display: inline-block;
            margin-bottom: .2em;
        }
    }
    &-date {
        font-size: 1.2rem;
        color: $gris-;
    }

    &-tag {
        display: inline-block;
        padding: .1em .3em;
        border-radius: 4px;
        font-size: 1.4rem;
        color: white;

        &-bnssa {
            background-color: $orange;
        }
        &-mns {
            background-color: $rouge;
        }
        &-sb {
            background-color: $vert;
        }
        &-divers {
            background-color: $gris;
        }
    }

}
