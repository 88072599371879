/* ----------------------------- */
/* ==Layout/Global          */
/* ----------------------------- */

/* module, gains superpower "BFC" Block Formating Context */
.mod,
.bfc {
    overflow: hidden;
}

/* blocks that needs to be placed under floats */
.clear,
.line,
.row {
    clear: both;
}

/* blocks that must contain floats */
.clearfix,
.line {
    &::after {
        content: "";
        display: table;
        clear: both;
        border-collapse: collapse;
    }
}

/* simple blocks alignment */
.left {
    margin-right: auto;
}

.right {
    margin-left: auto;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

/* text and contents alignment */
.txtleft {
    text-align: left;
}

.txtright {
    text-align: right;
}

.txtcenter {
    text-align: center;
}

/* floating elements */
.fl {
    float: left;
}

img.fl {
    margin-right: 1rem;
}

.fr {
    float: right;
}

img.fr {
    margin-left: 1rem;
}

img.fl,
img.fr {
    margin-bottom: .5rem;
}

/* table layout */
.row {
    display: table;
    table-layout: fixed;
    width: calc(100% + #{$marge-horizontal});
    margin-left: -$marge-horizontal;
}

.row > *,
.col {
    display: table-cell;
    vertical-align: top;
    padding-left: $marge-horizontal;
    border: 1px solid transparent;
}

/* no table-cell for script tag when body is a .row */
body > script {
    display: none !important;
}

/* inline-block */
.inbl {
    display: inline-block;
    vertical-align: top;
}

/* flexbox layout
http://www.alsacreations.com/tuto/lire/1493-css3-flexbox-layout-module.html
*/

[class*="flex-container"] {
    display: flex;
    flex-wrap: wrap;
}

.flex-container-h {
    flex-direction: row;
}

.flex-container-v {
    flex-direction: column;
}

.flex-item-fluid {
    flex: 1;
}

.flex-item-first {
    order: -1;
}

.flex-item-medium {
    order: 0;
}

.flex-item-last {
    order: 1;
}

.flex-item-center {
    margin: auto;
}


@media (max-width: 640px) {

    /* quick small resolution reset */
    .row,
    .col,
    .mod,
    .col,
    fieldset {
        display: block !important;
        float: none !important;
        clear: none !important;
        width: auto !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        border: 0;
    }
}
