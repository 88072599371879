.contenu {
    word-break: break-word; /* pour chrome qui ne connait pas hyphens */
    hyphens: auto;

    &-link-inverse a {
        &:hover {
            color: $bleuf;
        }
    }

    h2 {
        padding-left: 1rem;
        border-left: 5px solid $orange;
        font-family: $font-titre;
        font-weight: normal;
        @extend .contenu-link-inverse
    }
    h3 {
        font-family: $font-titre;
        font-weight: normal;
    }
    h4 {
        font-family: $font-titre;
        font-weight: normal;
    }
    h5 {
        font-family: $font-titre;
        font-weight: normal;
    }

     > ul {

    }

    a {
        text-decoration: underline;
        &:hover, &:focus, &:active {
            text-decoration: none;
        }
    }

    mark {
        background-color: $gris-;
        color: white;

    }

    .lienexterne {

        &:after {
            content: '';
            display: inline-block;
            @extend .icon-external;
            margin-left: .2em;
        }
    }
    .reference  {
        font-size: 1rem;
        vertical-align: top;
        text-decoration: none;
        color: $gris-;

        &:after {
            content: '';
            display: inline-block;
            @extend .icon-external-reference;
            margin-left: .2em;
        }
        &:hover {
            text-decoration: underline;
        }
    }


    abbr, acronym {
        cursor: help;
    }

    blockquote {
        font-style: italic;
    }

    figure {
        margin: 0;
        display: inline-block;
        border: 1px dashed $gris--;
        padding: .5em;

        &.fr {
            margin-left: 1em;
        }
        &.fl {
            margin-right: 1em;
        }
    }
    figcaption {
        text-align: center;
        color: $gris--;
        font-style: italic;
        font-size: $font-small-size;
    }

    .source {
        text-align: right;
        font-style: italic;
        color: $gris;
    }
}

.small {
    font-size: $font-small-size;
}
