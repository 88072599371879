/* ==|====================
   Layout/Footer
   ======================= */

.l-footer {
    position: relative;
    padding: 5rem 0;
    background: $bleu;
    color: white;
}
.l-footer:before {
    content: '';
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    top: -1rem;
    background: url(../img/frise.png) 0 0 repeat-x;
}
    .footer-wrapper {
        @extend .l-wrapper;
        display: flex;
    }
        .footer-wrapper > *:first-child {
            flex: 0 1 450px;
        }
        .footer-wrapper > *:nth-child(2) {
            flex: 1 0 auto;
            padding: 0 $marge-horizontal;
        }
        .footer-wrapper > *:last-child{
            flex: 2 0 auto;
        }
.l-footer a {
    color: white;
}
.footer-titre {
    font-size: 2rem;
    font-family: $font-titre;
    font-weight: normal;
}
.footer-facebook {
    display: inline-block;
    min-width: 200px;
    margin-top: $marge-vertical * 2;
    font-size: $font-small-size;

    &:before {
        content: '';
        float: left;
        margin-right: .8em;
        margin-top: .3em;
        @include sprite($icon-facebook);
    }
}

.footer-top {
    display: none;
    position: fixed;
    right: 10px;
    bottom: 20px;
    min-width: 40px;
    min-height: 35px;
    border-radius: 4px;
    background-color: rgba($gris--, .8);

    &:hover {
        background-color: $gris-;
    }

    &:before {
        content: '';
        display: block;
        margin: 11px 0 0 10px;
        @include sprite($icon-top);
    }

    &-text {
        @extend .visually-hidden;
    }
}

@media (max-width: 800px) {
    .footer-wrapper {
        flex-flow: column;
    }
    .footer-wrapper > * {
        flex: 1 !important;
        padding: 10px 0;
    }
}