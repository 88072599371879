/* ===========================
   Base
   =========================== */

html {
    box-sizing: border-box;
}

* {
    box-sizing: inherit;
}

body {
    background: white;
    font-family: $font-base;
}


html {

    /* set base font-size to equiv "10px", which is adapted to rem unit */
    font-size: 62.5%;

    /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
    /* thanks to @guardian, @victorbritopro and @eQRoeil */
    font-size: calc(1em * 0.625);
}

body {
    font-size: $font-base-size;
    font-family: $font-base;

    background-color: white;
    color: $color-base;
}

/* max values */
img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
video,
svg {
    max-width: 100%;
}

img {
    height: auto;
}

a {
    color: inherit;
    text-decoration: none;
    &:hover, &:focus, &:active {
        color: inherit;
    }
    &:hover {
        text-decoration: underline;
    }
}

label {
    cursor: pointer;
}

*::selection {
    color: white;
    background-color: $vert;
}