
.cercle {
    display: flex;
    width: 100px;
    min-height: 100px;
    padding: 1em;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;

    &-txt {
        margin: auto;
    }

    &-danger {
        background-color: $danger;
        color: white;
    }
    &-success {
        background-color: $success;
        color: white;
    }
}