
$pictos: getSpritesByPrefixe($spritesheet, ('picto'));
@each $key, $sprite in $pictos {
    $sprite-name: map-get($sprite, 'name');

    .#{$sprite-name}:before {
        @extend .icon-#{$sprite-name};
    }
}

$rub-img-max-height: 86px;
$rub-width: 158px;

.pictos ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -$marge-horizontal;
    @extend .ul-unstyled;

    > li {
        flex: 0 0 $rub-width;
        margin: 1rem 0 1rem $marge-horizontal;
    }
}



.picto {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: $rub-width;
    height: 100%;
    padding: 1rem .5rem 1rem .5rem;
    border: 1px solid $bleu--;
    background: $bleu-----;
    font-family: $font-titre;
    text-align: center;
    color: $bleu-xx;

    &:before {
        content: '';
        display: block;
        margin: auto auto;
    }
    &-titre {
        min-height: 4rem;
        margin: 1rem 0 0 0;
        font-size: $font-base-size;
        font-weight: normal;
    }
}
a.picto:hover {
    text-decoration: none;
    background: $bleu---;
}
