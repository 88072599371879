/* ==|====================
   Layout/Main
   ======================= */

.l-wrapper {
    max-width: $site-max-width;
    margin: 0 auto;
    padding: 0 10px;
}

.l-main {
    margin-top: 57px;
    margin-bottom: 6rem;
}
    .main-header {

    }
        .main-bandeau {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            min-height: 130px;
            background-color: $orange;
        }
            .main-titre {
                background-color: rgba(255, 153, 0, .6);
                color: white;
            }
                .main-titre-txt {
                    @extend .l-wrapper;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    font-size: 3rem;
                    font-family: $font-titre;
                    font-weight: normal;
                }
                .main-titre-description {
                    @extend .l-wrapper;
                    padding-bottom: 1rem;
                }
        .main-page .main-bandeau {
            min-height: 200px;
            background-repeat: no-repeat;
            background-position: center top;
            background-size: cover;
        }
        .main-nav {
            background-color: $gris----;
            font-size: 1.1rem;
        }
            .main-nav-wrapper {
                @extend .l-wrapper;
                display: flex;
                min-height: 50px;
            }
                .main-nav-wrapper > *:first-child {
                    margin: auto 0;
                    flex: 1;
                }
                .main-nav-wrapper > *:nth-child(2) {
                    flex: 0 1 420px;
                    //border-left: 4px solid white;
                }
                    .main-nav a {
                        color: $color-base;
                    }
        .main-infos {
            @extend .l-wrapper;
            display: flex;
            padding-right: $asite-width + 30px;
            font-size: 1.1rem;
            color: $gris--;

            & > *:first-child {
                flex: 1;
            }
        }
        .main-admin {
            @extend .l-wrapper;
            padding-top: 1em;
            padding-right: $asite-width + 30px;
            font-size: 1rem;
            text-align: right;

            & ul {
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                }
                &:last-child a {
                    margin-right: 0;
                }
            }
        }


    .l-main-grid {
        display: flex;
        margin-top: $marge-vertical;

        > *:first-child {
            padding-right: $marge-horizontal;
        }
    }
        .l-aside {
            width: $asite-width;
        }
        .aside-lg {
            width: $asite-large-width;
        }
        .l-main-principal {
            flex: 1;
         }

@media (max-width: 940px) {
    .l-main-grid {
        display: block;
    }
    .l-main-grid > * {
        width: auto;
        margin-top: $marge-vertical;
        padding: 0;
    }
    .main-infos {
        padding-right: $marge-horizontal;
    }
}

@media (max-width: 840px) {
    .main-nav-wrapper {
        flex-flow: column;
    }
    .main-nav-wrapper > *:last-child {
        flex: 1;
    }
    .l-main-grid {
        > *:first-child {
            padding-right: 0;
        }
    }
}

