.pagination {
    @extend .ul-unstyled;

    li {
        display: inline-block;
        padding-bottom: 1rem;
    }
    span, a {
        display: inline-block;
        margin-left: .5em;
        padding: .2em .5em;
        border: 1px solid $gris---;
    }
    a {
        &:hover {
            text-decoration: none;
            background-color: $orange---;
        }
    }
    .active span {
        background-color: $orange;
        color: white;
    }
}
