@charset "UTF-8";

.btn {
    display: inline-block;
    margin: .5rem;
    padding: .4em .5em;
    border: 0 none;
    border-radius: 3px;

    &:hover {
        text-decoration: none;
    }

    &-large {
        font-size: 2rem;
        font-weight: bold;
        line-height: 2em;
    }

    &-block {
        display: block;
        text-align: center;
    }

    &-inverse {
        background-color: $bleu-;
        color: white;

        &:hover {
            background-color: $bleu---;
        }
    }

    &-link {
        color: $bleuf;

        &:hover {
            text-decoration: underline;
        }
        &:before {
            content: '→';
            display: inline-block;
            padding-right: .5rem;
        }
    }

    &-call {
        background-color: $orange;
        color: white;

        &:hover {
            background-color: $orange-;
            color: white;
        }
    }

    &-base {
        background-color: $gris-;
        color: white;

        &:hover {
            background-color: $gris--;
            color: white;
        }
    }

    &-danger {
        background-color: $danger;
        color: white;

        &:hover {
            background-color: lighten($danger, 10%);;
            color: white;
        }
    }
}