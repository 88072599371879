/* ===========================
   Fonts
   =========================== */

@font-face {
    font-family: 'paloaltoheavy';
    src: url('../fonts/Palo_Alto_Regular-webfont.eot');
    src: url('../fonts/Palo_Alto_Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Palo_Alto_Regular-webfont.woff') format('woff'),
    url('../fonts/Palo_Alto_Regular-webfont.ttf') format('truetype'),
    url('../fonts/Palo_Alto_Regular-webfont.svg#paloaltoheavy') format('svg');
    font-weight: normal;
    font-style: normal;
}