.lastNext {
    margin-top: 1em;
    align-self: stretch;

    ul {
        @extend .ul-unstyled;
        margin: auto 0;
        display: flex;

        li {
            padding: 0 0.5em;
            display: flex;
            flex: 0 0 50%;
        }
        li:first-child {
            border-right: 2px solid $gris------;
        }
    }

    &-last {
        margin: auto 0;

        &:before {
            content: '';
            float: left;
            margin-right: .5em;
            @extend .icon-fleche-left;
        }
    }
    &-next {
        margin: auto 0;
        text-align: right;

        &:before {
            content: '';
            float: right;
            margin-left: .5em;
            @extend .icon-fleche-right;
        }
    }
}