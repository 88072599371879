.anim {
    position: relative;
    min-height: 368px;
    background-color: #DEE9FF;
    font-family: $font-titre;
    color: $gris-;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 20px;
        width: 100%;
        height: 36px;
        background: url(../img/bandeau-home-nuage.png)  0 0 no-repeat;
        animation: anim-nuage 20s infinite linear;
    }
    @keyframes anim-nuage {
        0% { background-position: -105px 0;  }
        100% { background-position: 120% 0; }
    }
    &-vague1 {
        position: absolute;
        width: 100%;
        bottom: 50px;
        height: 91px;
        background: transparent url(../img/bandeau-home-vague1.png) bottom center repeat-x;
        animation: anim-vague1 5s infinite linear;
    }
    @keyframes anim-vague1 {
        0% { bottom: 50px; background-position: 0 bottom }
        25% { bottom: 55px; background-position: 15px bottom }
        50% { bottom: 60px; background-position: 0 bottom }
        75% { bottom: 55px; background-position: -15px bottom }
        100%{ bottom: 50px; background-position: 0 bottom }
    }
    &-vague2 {
        position: absolute;
        z-index: 2;
        width: 100%;
        bottom: 20px;
        height: 90px;
        background: transparent url(../img/bandeau-home-vague2.png) bottom center repeat-x;
        animation: anim-vague2 5s infinite linear;
    }
    @keyframes anim-vague2 {
        0% { bottom: 33px; background-position: 0 bottom }
        25% { bottom: 25px; background-position: -15px bottom }
        50% { bottom: 20px; background-position: 0 bottom }
        75% { bottom: 25px; background-position: 15px bottom }
        100%{ bottom: 33px; background-position: 0 bottom }
    }
    &-vague3 {
        position: absolute;
        z-index: 2;
        width: 100%;
        bottom: 0;
        height: 88px;
        background: transparent url(../img/bandeau-home-vague3.png) bottom center repeat-x;
    }

    &-wrapper {
        @extend .l-wrapper;
        position: relative;
        min-height: 368px;

        &:before {
            content: '';
            display: block;
            top: 25px;
            left: 0;
            position: absolute;
            width: 271px;
            height: 115px;
            background: url(../img/bandeau-home-nuages.png)  0 0 no-repeat;
        }
        &:after {
            content: '';
            display: block;
            top: 45px;
            right: 100px;
            position: absolute;
            width: 122px;
            height: 101px;
            background: url(../img/bandeau-home-secours.png)  0 0 no-repeat;
        }
    }

    &-titre {
        position: relative;
        z-index: 1;
        padding-top: 40px;
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
    }
    &-soustitre {
        position: relative;
        z-index: 1;
        padding: 3rem 0 1rem 0;
        text-align: center;
        font-size: 1.9rem;
    }
    &-mains {
        position: absolute;
        bottom: 40px;
        right: 150px;
        width: 108px;
        height: 152px;
        background: url(../img/bandeau-home-mains.png)  0 0 no-repeat;
        animation: anim-mains 5s infinite;
    }
    @keyframes anim-mains {
        0% { bottom: 40px; }
        50% { bottom: 0; }
        100%{ bottom: 40px; }
    }
}

@media (max-width: 940px) {
    .anim,
    .anim-wrapper {
        min-height: auto;
    }
    .anim-vague1,
    .anim-vague2,
    .anim-vague3,
    .anim-mains,
    .anim-wrapper:before,
    .anim-wrapper:after {
        display: none;
    }
}