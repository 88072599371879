/* ==|====================
   Layout/Grids
   ======================= */

.grid-3 {
    @include grid(3);

    @media (max-width: 680px) {
        & > * {
            @include grid-item(2);
        }
    }
    @media (max-width: 500px) {
        & > * {
            @include grid-item(1);
        }
    }
}


.grid-2 {
    @include grid(2);

    @media (max-width: 640px) {
        & > * {
            @include grid-item(1);
            // margin-left: 0; TODO
        }
    }
}
