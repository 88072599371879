.qcm {
    @extend .box1;
    padding-left: 1em;
    padding-right: 1em;

    &:hover {
        background-color: $gris------;
    }

    &-ok {
        border-left: 4px dashed transparent;
    }
    &-ko {
        border-left: 4px dashed $rouge;
    }

    &-titre {
        @extend .titre;
        @extend .titre-3;
    }
    &-reponses {
        @extend .ul-unstyled;

        li {
            padding: .4em 0;
        }
    }

    &-reponse {
        display: flex;
        align-items: center;

        &-label {
            flex: 1;
            margin-left: .5em;
        }

        & input:checked ~ &-label {
            background: $bleu---;
        }

        &-ok &-label {
            font-weight: normal;
            color: $vert;
            cursor: default;
        }
        &-ko &-label {
            text-decoration: line-through;
            color: $gris--;
            cursor: default;
        }

        &-input {
            display: flex;
            align-items: center;
            padding: 2px 5px;
            border-radius: 5px;

            &:before {
                content: '';
                display: block;
                @extend .icon-exclamation;
            }
            input {
                margin-left: 5px;
            }
        }

        &-ok &-input {
            background-color: $orange;
        }
        &-ok.isChecked &-input {
            background-color: $vert; //lighten($vert, 60%);
            &:before {
                background-image: none;
            }
        }
        &-ko &-input {
            background-color: $gris----;
            &:before {
                background-image: none;
            }
        }
        &-ko.isChecked &-input {
            background-color: $rouge;
            &:before {
                @include sprite($icon-exclamation);
            }
        }

    }
}