.form  {

    &-control {
        display: block;
        width: 100%;
        padding: .3em .5em;
        background-color: white;
        background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
        border: 1px solid $gris---;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

        &:focus {
            border-color: $bleuf;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 4px rgba($bleuf, 0.3);
        }

        &-danger {
            border-color: $danger;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 4px rgba($danger, 0.3);
        }

        &-success {
            border-color: $success-;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 4px rgba($success-, 0.3);
        }

        &:focus:invalid, &-danger:focus {
            @extend  .form-control-danger;
        }


        // Unstyle the caret on `<select>`s in IE10+.
        &::-ms-expand {
            border: 0;
            background-color: transparent;
        }
    }
    label {
        display: inline-block;
        margin-bottom: .5em;
        font-weight: bold;
    }
    textarea {
        resize: vertical;
        vertical-align: top;
    }
    form,
    fieldset {
        border: none;
    }
    legend {
        border: 0;
        white-space: normal;
    }

    &-required {
        label:after {
            content: ' * requis';
            display: inline;
            font-size: .8em;
            color: $warning;
        }
    }
    &-recommended {
        label:after {
            content: ' * recommandé';
            display: inline;
            font-size: .8em;
            color: $info;
        }
    }

    &-password {

        &-command {
            position: absolute;
            z-index: 1;
            top: 1em;
            right: .6em;
            font-size: 1rem;
            cursor: pointer;
            color: $gris-;

            &:hover {
                color: $gris;
            }
        }

        &-wrapper {
            position: relative;
        }
    }
}


